import { Provider } from 'react-redux';

import './global.css';
import store from './store';
import ThemeProvider from './theme';
import Router from './routes/sections';
import CustomSnackbar from './components/Snackbar';
import { useScrollToTop } from './hooks/use-scroll-to-top';

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <Provider store={store}>
      <ThemeProvider>
        <Router />
        <CustomSnackbar />
      </ThemeProvider>
    </Provider>
  );
}

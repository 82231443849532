import 'dayjs/locale/en-gb';
import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, Typography, CircularProgress } from '@mui/material';

import PrivateRoute from './PrivateRoute';
import DashboardLayout from '../layouts/dashboard';

const IndexPage = lazy(() => import('../pages/app'));
const UserPage = lazy(() => import('../pages/user'));
const LoginPage = lazy(() => import('../pages/Login'));
const Page404 = lazy(() => import('../pages/page-not-found'));
const FrameworkDetails = lazy(() => import('../pages/FrameworkDetails'));
const FrameworkTemplate = lazy(() => import('../pages/FrameworkTemplate'));
const EditFramework = lazy(() => import('../pages/EditFramework'));
const FrameworkTree = lazy(() => import('../pages/FrameworkTreePage'));
const ScheduleView = lazy(() => import('../pages/ScheduleView'));
const Audit = lazy(() => import('../pages/Audit'));
const Report = lazy(() => import('../pages/Report'));

const LoadingFallback = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '70vh',
    }}
  >
    <CircularProgress />
    <Typography sx={{ mt: 2 }}>Loading...</Typography>
  </Box>
);

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      element: (
        <PrivateRoute>
          <DashboardLayout>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <Suspense fallback={<LoadingFallback />}>
                <Outlet />
              </Suspense>
            </LocalizationProvider>
          </DashboardLayout>
        </PrivateRoute>
      ),
      children: [
        { element: <IndexPage />, index: true },
        { path: 'user', element: <UserPage /> },
        { path: 'framework/:frameworkId', element: <FrameworkDetails /> },
        { path: 'template/create', element: <FrameworkTemplate /> },
        { path: 'edit-framework/:id', element: <EditFramework /> },
        { path: 'framework-tree', element: <FrameworkTree /> },
        { path: 'schedule', element: <ScheduleView /> },
        { path: 'audit', element: <Audit /> },
        { path: 'report', element: <Report /> }
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}

import { jwtDecode } from 'jwt-decode';

import axios from '../api/axiosConfig';
import { login, logout } from './authSlice';
import { showSnackbar } from './snackbarSlice';

const setCookie = (name, value, days) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
};

const getCookie = (name) =>
  document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');

export const loginUser = (email, password) => async (dispatch) => {
  try {
    const response = await axios.post('/users/login', { email, password });

    // Extract token from response
    let { token } = response.data;

    // Fallback: Extract token from cookies if not in response
    if (!token) {
      await new Promise((resolve) => setTimeout(resolve, 100));
      token = getCookie('token');
    } else {
      // Set token in cookies if it's in the response
      setCookie('token', token, 1); // Set cookie to expire in 1 day
    }

    if (token) {
      const decodedToken = jwtDecode(token); // Decode the token to get user data
      dispatch(login(decodedToken));
      dispatch(showSnackbar({ message: 'Login successful!', severity: 'success' }));
      return true;
    }
    throw new Error('Token not found');
  } catch (error) {
    dispatch(
      showSnackbar({ message: 'Login failed. Please check your credentials.', severity: 'error' })
    );
    console.error('Login failed', error);
    return false;
  }
};

// Logout action
export const logoutUser = () => async (dispatch) => {
  try {
    await axios.post('/users/logout');
    dispatch(logout());
    dispatch(showSnackbar({ message: 'Logout successful!', severity: 'success' }));
  } catch (error) {
    dispatch(showSnackbar({ message: 'Logout failed. Please try again.', severity: 'error' }));
    console.error('Logout failed', error);
  }
};
